import React, { useEffect } from "react";

import NavbarRoute from "./NavbarRoute";

import MainImg from "../assets/images/ScottCorkery.jpg";

import "./Routes.css";

const ScottCorkeryRoute = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bryan-route-container circuit-board-wp">
        <NavbarRoute />
        <span></span>
        <div className="route-content-container">
          <div className="bryan-route-content-left">
            <img src={MainImg} alt="bryan" className="route-img" />
            <h1 className="bryan-route-headerone no-margin">
              SCOTT W. CORKERY, CPA, CA
            </h1>
            <h2 className="bryan-route-headertwo no-margin">
              CHIEF FINANCIAL OFFICER
            </h2>
          </div>
          <div className="bryan-route-content-right">
            <p className="no-margin-top">
              Scott W. Corkery serves as our Chief Financial Officer and plays a
              critical role in managing the company’s past and future financial
              obligations. He’s also responsible for providing accurate and
              timely financial reporting, and determining the appropriate mix of
              debt and equity across investment portfolios currently under
              management. Scott’s background includes extensive experience in
              the hedge fund, private equity, and real estate accounting space.
              Before joining StratCap Wireless, Scott was a Managing Director
              and Head of Private Equity and Real Estate Accounting for Sculptor
              Capital. Prior to Sculptor, Scott was an Assistant Controller at
              York Capital Management. He was also a VP/Assistant Manager on the
              GSAS Toronto team at Goldman Sachs.
            </p>
            <p>
              Scott holds a Bachelor of Science in Biology from Dalhousie
              University and a Bachelor of Commerce from Saint Mary’s
              University. Scott is also a Chartered Accountant and Chartered
              Professional Accountant with the Institute of Chartered
              Accountants of Nova Scotia, Canada.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScottCorkeryRoute;
