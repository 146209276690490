import React, { useEffect } from "react";

import NavbarRoute from "./NavbarRoute";

import MainImg from "../assets/images/StratCap-CarlPacheco.jpg";

import "./Routes.css";

const CarlPRoute = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bryan-route-container circuit-board-wp">
        <NavbarRoute />
        <span></span>
        <div className="route-content-container">
          <div className="bryan-route-content-left">
            <img src={MainImg} alt="Carl Pacheco" className="route-img" />
            <h1 className="bryan-route-headerone no-margin">CARL PACHECO</h1>
            <h2 className="bryan-route-headertwo no-margin">
              OPERATIONS MANAGER WIRELESS INFRASTRUCTURE GROUP
            </h2>
          </div>
          <div className="bryan-route-content-right">
            <p className="no-margin-top">
              Mr. Pacheco will serve as Operations Manager and comes to the
              company with technical expertise in mechanical and electrical
              systems, facilities management, and logistics. In his role at
              StratCap Wireless, Mr. Pacheco will be responsible for managing
              tower properties, which includes working closely with carrier
              technicians, performing maintenance on towers and buildings when
              applicable, leading quarterly site inspections, working with
              outside engineers and technicians when upgrading equipment, as
              well as develop and maintain tenant relationships. Previously, Mr.
              Pacheco worked in commercial construction as a Project Manager
              with W.E. O’Neil Southland, Southern Land Company, and Bergman
              KPRS. He also spent 20 years serving in the US Coast Guard where
              he was responsible for government contracting for service assets
              and weapons systems. The majority of Carl’s years in the Coast
              Guard were spent on the east coast, but he retired in California
              before making a more permanent home in Tennessee where he resides
              with his wife and two youngest children.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarlPRoute;
