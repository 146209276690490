import React from "react";

import "./AboutUs.css";

const AboutUs = (props) => {
  return (
    <>
      <div ref={props.refProp} className="aboutus-container" id="about-us">
        <h2 className="heading-white green-heading">About us</h2>
        <div className="about-us-content-wrapper">
          <h2 className="text-uppercase sub-heading gray-text h-2">A TRUSTED PARTNER</h2>
          <h5 className="gray-text h-5">
            To help achieve our investment goals, we develop strategic
            relationships with select independent operators and developers in
            order to acquire, build and manage a variety of telecommunications
            infrastructure assets throughout the U.S.
          </h5>
          <br />
          <h5 className="gray-text h-5">
            We take pride in establishing a true, mutually beneficial
            partnership with operators and developers that aligns interests,
            provides transparency and offers fair pricing to ensure a
            longstanding working relationship for years to come.
          </h5>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
