import React, { useEffect } from "react";

import NavbarRoute from "./NavbarRoute";
// import NavbarRoute from "../components/TEST/NavReference";

import MainImg from "../assets/images/RyanWhite.jpg";

import "./Routes.css";

const RyanPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bryan-route-container circuit-board-wp">
        <NavbarRoute />
        <span></span>
        <div className="route-content-container">
          <div className="bryan-route-content-left">
            <img src={MainImg} alt="bryan" className="route-img" />
            <h1 className="bryan-route-headerone no-margin text-uppercase">
              Ryan white
            </h1>
            <h2 className="bryan-route-headertwo no-margin text-uppercase">
              Vice President of Business Development
            </h2>
          </div>
          <div className="bryan-route-content-right">
            <p className="no-margin-top">
              Ryan White serves as Vice President of Business Development. Mr.
              White brings over 20 years’ experience in wireless infrastructure,
              mergers and acquisitions, strategic sourcing and business
              development. He is instrumental in supporting operations,
              underwriting, modeling, contract management, project management,
              and deal closings. Before joining Strategic Wireless, Mr. White
              was a Managing Partner at Madson Capital & Advisors where he was
              responsible for sales processes for small to large wireless
              infrastructure businesses, financial modeling, divestiture
              planning and more. Mr. White holds an MBA in Finance and Marketing
              from Rockhurst University in Kansas City, MO.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RyanPage;
