import React, { useEffect } from "react";

import NavbarRoute from "./NavbarRoute";

import MainImg from "../assets/images/BrandonHunt.jpg";

import "./Routes.css";

const BrandonHuntRoute = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bryan-route-container circuit-board-wp">
        <NavbarRoute />
        <span></span>
        <div className="route-content-container">
          <div className="bryan-route-content-left">
            <img src={MainImg} alt="bryan" className="route-img" />
            <h1 className="bryan-route-headerone no-margin">BRANDON HUNT</h1>
            <h2 className="bryan-route-headertwo no-margin">
              PORTFOLIO MANAGER
            </h2>
          </div>
          <div className="bryan-route-content-right">
            <p className="no-margin-top">
              Brandon Hunt currently serves as the Portfolio Manager for
              StratCap Wireless.
            </p>
            <p>
              Brandon has over 12 years of experience in the alternative
              investment industry and has been involved in over $7B in
              investment offerings. He plays integral roles in product
              development, fund management, market research and thought
              leadership for the company. He is also instrumental in product and
              industry training as well as interfacing with institutional firms,
              due diligence personnel and financial advisors regarding the
              intricacies of the company’s investment strategy.
            </p>
            <p>
              Brandon graduated Magna Cum Laude from the University Honors
              Program at California State University Fullerton. His studies
              included real estate and corporate finance, with a special
              interest in alternative investments and derivatives. He was also
              recipient of the CSUF College of Business and Economics Alumni
              Scholarship, CSUF Dean’s Advisory Board Scholarship and the TABR
              Capital Management Scholarship.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandonHuntRoute;
