import React, { useEffect } from "react";

import NavbarRoute from "./NavbarRoute";

import MainImg from "../assets/images/StratCap-EricGraham.jpg";

import "./Routes.css";

const EricGrahamRoute = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bryan-route-container circuit-board-wp">
        <NavbarRoute />
        <span></span>
        <div className="route-content-container">
          <div className="bryan-route-content-left">
            <img src={MainImg} alt="ERIC GRAHAM" className="route-img" />
            <h1 className="bryan-route-headerone no-margin">ERIC GRAHAM</h1>
            <h2 className="bryan-route-headertwo no-margin">
              GENERAL COUNSEL
            </h2>
          </div>
          <div className="bryan-route-content-right">
            <p className="no-margin-top">
              Eric Graham has almost 20 years of experience in the
              telecommunications industry with extensive knowledge of landline,
              fiber, wireless, and satellite networks. As General Counsel and
              Senior Vice President, Strategic Initiatives, Mr. Graham is part
              of the team that invests in wireless infrastructure opportunities
              and he has primary responsibility for completing the acquisition
              process and managing all legal aspects of these acquisitions. Mr.
              Graham’s responsibilities throughout his career have spanned
              legal, corporate development, government relations, and regulatory
              affairs. Regardless of title, strategy has remained at the core of
              his role.
            </p>
            <p>
              Mr. Graham most recently has been providing legal consulting
              services to the Company managing the purchase of certain tower
              assets and he was also working for the satellite company, OneWeb,
              overseeing certain legal and regulatory responsibilities. Prior to
              that, he spent 13 years with C Spire in various executive roles. C
              Spire is the fifth largest wireless operator in the U.S. and a
              local wireline telephone/broadband provider, covering primarily
              the state of Mississippi and the Florida panhandle. There, his
              work included acquisitions and sales of large portfolios of
              wireless assets including spectrum, infrastructure, and operating
              businesses. His work led to the expansion of C Spire’s fiber
              business from a primarily captive fiber backhaul provider to a
              leading regional enterprise and consumer fiber provider large
              enough to displace the legacy Bell company as the sole-source
              provider of wireline services in the state of Mississippi. He has
              appeared as a witness before several committees of the United
              States Senate and United States House of Representatives,
              participated in numerous Federal Communications Commission panels
              and working groups, and has been a delegate to various
              international standards group meetings around the world.
            </p>
            <p>
              Mr. Graham holds Bachelor of Arts and Master of Public Policy and
              Administration degrees from Mississippi State University in
              Starkville, Mississippi, a Juris Doctor degree from the
              Mississippi College School of Law in Jackson, Mississippi, and a
              Master of Business Administration degree from the Else School of
              Management at Millsaps College in Jackson, Mississippi.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EricGrahamRoute;
