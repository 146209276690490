import { BrowserRouter as Router, Route, HashRouter } from "react-router-dom";
import React, { useReducer, createContext, useEffect } from "react";

import Home from "./components/home/Home";
import RyanPage from "./routes/ryanPage";
import ToddPage from "./routes/toddPage";
import ScottPage from "./routes/scottPage";
import KarenRoute from "./routes/karenPage";
import ScottJacobsRoute from "./routes/scottJPage";
import BrandonHuntRoute from "./routes/brandonHPage";
import ScottCorkeryRoute from "./routes/scottCPage";
import ChristineNguyenRoute from "./routes/christineNPage";
import MattSimmavongRoute from "./routes/mattSPage";
import EricGrahamRoute from "./routes/ericGPage";
import BrianERoute from "./routes/brianEPage";
import RobertMPage from "./routes/robertMPage";
import CarlPRoute from "./routes/carlPPage";
import SealeDPage from "./routes/SealeDPage";

import LoginPage from "./components/login/LoginPage";

import "./App.css";

// Auth Context will pass the auth state from this component to any other component that needs it.
export const AuthContext = createContext();
const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      };
    case "LOGOUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

function App() {
  const password = process.env.REACT_APP_PASS;
  const envType = process.env.REACT_APP_TYPE;
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const token = sessionStorage.getItem("userpass");

    if (token && token === password) {
      dispatch({
        type: "LOGIN",
        payload: {
          token,
        },
      });
    }
  }, []);

  const renderLogin = () => {
    if (envType === "test") {
      return (
        <HashRouter>
          <AuthContext.Provider value={{ state, dispatch }}>
            <Route exact path="/">
              {!state.isAuthenticated ? <LoginPage /> : <Home />}
            </Route>
            <Route path="/ryanwhite">
              {!state.isAuthenticated ? <LoginPage /> : <RyanPage />}
            </Route>
            <Route path="/toddrowley">
              {!state.isAuthenticated ? <LoginPage /> : <ToddPage />}
            </Route>
            <Route path="/scottriggs">
              {!state.isAuthenticated ? <LoginPage /> : <ScottPage />}
            </Route>
            <Route path="/karensiu">
              {!state.isAuthenticated ? <LoginPage /> : <KarenRoute />}
            </Route>
            <Route path="/scottjacobs">
              {!state.isAuthenticated ? <LoginPage /> : <ScottJacobsRoute />}
            </Route>
            <Route path="/brandonhunt">
              {!state.isAuthenticated ? <LoginPage /> : <BrandonHuntRoute />}
            </Route>
            <Route path="/scottcorkery">
              {!state.isAuthenticated ? <LoginPage /> : <ScottCorkeryRoute />}
            </Route>
            <Route path="/christinenguyen">
              {!state.isAuthenticated ? (
                <LoginPage />
              ) : (
                <ChristineNguyenRoute />
              )}
            </Route>
            <Route path="/mattsimmavong">
              {!state.isAuthenticated ? <LoginPage /> : <MattSimmavongRoute />}
            </Route>
            <Route path="/ericgraham">
              {!state.isAuthenticated ? <LoginPage /> : <EricGrahamRoute />}
            </Route>
            <Route path="/brianeng">
              {!state.isAuthenticated ? <LoginPage /> : <BrianERoute />}
            </Route>
            <Route path="/robertmoretta">
              {!state.isAuthenticated ? <LoginPage /> : <RobertMPage />}
            </Route>
            <Route path="/carlpacheco">
              {!state.isAuthenticated ? <LoginPage /> : <CarlPRoute />}
            </Route>
            <Route path="/sealeduggar">
              {!state.isAuthenticated ? <LoginPage /> : <SealeDPage />}
            </Route>
          </AuthContext.Provider>
        </HashRouter>
      );
    } else {
      return (
        <HashRouter>
          <div className="App">
            <Route exact path="/" component={Home} />
            <Route path="/ryanwhite" component={RyanPage} />
            <Route path="/toddrowley" component={ToddPage} />
            <Route path="/scottriggs" component={ScottPage} />
            <Route path="/karensiu" component={KarenRoute} />
            <Route path="/scottjacobs" component={ScottJacobsRoute} />
            <Route path="/brandonhunt" component={BrandonHuntRoute} />
            <Route path="/scottcorkery" component={ScottCorkeryRoute} />
            <Route path="/christinenguyen" component={ChristineNguyenRoute} />
            <Route path="/mattsimmavong" component={MattSimmavongRoute} />
            <Route path="/ericgraham" component={EricGrahamRoute} />
            <Route path="/brianeng" component={BrianERoute} />
            <Route path="/robertmoretta" component={RobertMPage} />
            <Route path="/carlpacheco" component={CarlPRoute} />
            <Route path="/sealeduggar" component={SealeDPage} />
          </div>
        </HashRouter>
      );
    }
  };
  return <>{renderLogin()}</>;
}

export default App;
