import React, { useEffect } from "react";

import NavbarRoute from "./NavbarRoute";

import MainImg from "../assets/images/ScottJacobs.jpg";

import "./Routes.css";

const ScottJacobsRoute = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bryan-route-container circuit-board-wp">
        <NavbarRoute />
        <span></span>
        <div className="route-content-container">
          <div className="bryan-route-content-left">
            <img src={MainImg} alt="bryan" className="route-img" />
            <h1 className="bryan-route-headerone no-margin">
              SCOTT JACOBS, MBA
            </h1>
            <h2 className="bryan-route-headertwo no-margin">
              VICE PRESIDENT OF ACQUISITIONS AND ASSET MANAGEMENT
            </h2>
          </div>
          <div className="bryan-route-content-right">
            <p className="no-margin-top">
              Scott Jacobs is the Vice President of Acquisitions and Asset
              Management and has extensive experience in commercial real estate
              investment and business strategy. He is a key member of the
              wireless infrastructure acquisition team and is actively involved
              in corporate finance strategy, underwriting new opportunities,
              financial modeling, asset management, asset and fund-level
              financing, and dispositions.
            </p>
            <p>
              Before joining StratCap Wireless, Scott led the financial analysis
              team at Northstar Commercial Partners, was a key member of the
              investment team at Amstar, and worked at Credit Suisse in the Real
              Estate Investment Banking group. In his prior roles, Scott focused
              on both domestic and international investments and development
              opportunities across a variety of commercial real estate sectors
              (data centers, office, industrial, multifamily, storage, and
              hospitality). Most recently, he worked on the underwriting and
              analysis of a $350M data center development program with a top-5
              tech company.
            </p>
            <p>
              Scott earned his M.B.A. from the Yale School of Management,
              concentrating in real estate and finance, where he also received a
              scholarship from the Pension Real Estate Association. For his
              undergraduate studies at Penn State, Scott was a student athlete
              on the lacrosse team and earned a B.S. in Engineering Science with
              a minor in Nanotechnology from the Schreyer Honors College.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScottJacobsRoute;
