import React, { useEffect } from "react";

import NavbarRoute from "./NavbarRoute";

import MainImg from "../assets/images/StratCap-RobertMoreta.jpg";

import "./Routes.css";

const RobertMPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bryan-route-container circuit-board-wp">
        <NavbarRoute />
        <span></span>
        <div className="route-content-container">
          <div className="bryan-route-content-left">
            <img src={MainImg} alt="robert moretta" className="route-img" />
            <h1 className="bryan-route-headerone no-margin">ROBERT MORETTA</h1>
            <h2 className="bryan-route-headertwo no-margin">
              ASSISTANT CONTROLLER
            </h2>
          </div>
          <div className="bryan-route-content-right">
            <p className="no-margin-top">
              Robert Moretta currently serves as Assistant Controller for
              Strategic Wireless. He has extensive experience in the private
              equity and hedge fund accounting space. Before joining the
              company, Robert was a Fund Accounting Manager at Sculptor Capital,
              and before that role, he served as Assurance Supervisor at RSM US
              LLP. He also previously worked as an Audit Associate at Raich Ende
              Malter & Co. LLP.
            </p>
            <p>
              Robert holds a Bachelor of Arts in Economics from the University
              of Albany and a Master of Business Administration in Accounting
              from St. Joseph’s College. He is also a Certified Public
              Accountant.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RobertMPage;
