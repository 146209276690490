import React, { useEffect } from "react";

import NavbarRoute from "./NavbarRoute";

import MainImg from "../assets/images/MattSimmavong.jpg";

import "./Routes.css";

const MattSimmavongRoute = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bryan-route-container circuit-board-wp">
        <NavbarRoute />
        <span></span>
        <div className="route-content-container">
          <div className="bryan-route-content-left">
            <img src={MainImg} alt="bryan" className="route-img" />
            <h1 className="bryan-route-headerone no-margin">MATT SIMMAVONG</h1>
            <h2 className="bryan-route-headertwo no-margin">
              SENIOR ACCOUNTANT
            </h2>
          </div>
          <div className="bryan-route-content-right">
            <p className="no-margin-top">
              Matthew is a Senior Accountant with over four years of accounting
              experience primarily in alternative investment asset management
              and non-profit organizations. Matthew currently serves as a Senior
              Accountant and is responsible for preparing the financial
              reporting for the company and its affiliates.
            </p>
            <p>
              Matthew holds a Bachelor of Science from the Mihaylo College of
              Business and Economics at California State University Fullerton
              (CSUF) with a concentration in Accounting. His studies included
              auditing, financial accounting, cost accounting, and corporate
              finance.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MattSimmavongRoute;
