import React from "react";
import { Link } from "react-router-dom";

import "./Leadership.css";

// assets
import ryanImg from "../../assets/images/RyanWhite.jpg";
import toddImg from "../../assets/images/todd_small.jpg";
import scottImg from "../../assets/images/scott_small.jpg";
import ericGImg from "../../assets/images/StratCap-EricGraham.jpg";
import CarlPImg from "../../assets/images/StratCap-CarlPacheco.jpg";
import SealeDImg from "../../assets/images/StratCap-SealeDuggar.jpg"

import diverseImg from "../../assets/images/DIVERSIFIED.png";
import longstandingImg from "../../assets/images/LONGSTANDING.png";

const Leadership = (props) => {
  return (
    <>
      <div
        className="leadership_main_container"
        ref={props.refProp}
        id="leadership"
      >
        {/* top section start */}
        <div className="leadership-top-section">
          <h1 className="heading-blue green-heading">LEADERSHIP</h1>
          <p className="h-5 content-padding">
            Our executive team is made up of highly-seasoned telecommunications
            professionals that have completed in excess of $129 billion in
            telecommunications and infrastructure transactions and financings
            and have deep, longstanding relationships throughout the industry.
          </p>
          <div className="leadership-blue-bar-container">
            <div className="green-bar-card">
              <p className="leadership-content-right-card-p1">$129</p>
              <p className="leadership-content-right-card-p2">BILLION</p>
              <p className="leadership-content-right-card-p3">
                In wireless infrastructure related transactions and financings
              </p>
            </div>
            <div className="green-bar-card">
              <p className="leadership-content-right-card-p1">100+</p>
              <p className="leadership-content-right-card-p2">YEARS</p>
              <p className="leadership-content-right-card-p3">
                of combined experience sourcing, acquiring, constructing,
                financing and operating telecommunications infrastructure
                networks
              </p>
            </div>
            <div className="green-bar-card">
              <img
                src={diverseImg}
                className="leadership-icon-image"
                alt="diversified"
              />
              <p className="leadership-content-right-card-p3">
                diversified telecommunications infrastructure experience
                including cell towers, small cells, easements, fiber,
                distributed antenna systems and more
              </p>
            </div>
            <div className="green-bar-card">
              <img
                src={longstandingImg}
                alt="longstanding"
                className="leadership-icon-image"
              />
              <p className="leadership-content-right-card-p3">
                longstanding industry relationships with wireless carriers,
                contractors, network engineers, 3rd party providers and others
              </p>
            </div>
          </div>
        </div>
        {/* top section end ^ - bottom section start ↓ */}
        <div className="leadership_content">
          {/* row start */}
          <div className="team-row">
            {/* card start */}
            <div className="team-card">
              <Link to="/toddrowley">
                <div className="team-card-img-container">
                  <img src={toddImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">TODD ROWLEY</h6>
                  <p className="no-margin text-uppercase">
                    Chief Executive Officer
                  </p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/scottriggs">
                <div className="team-card-img-container">
                  <img src={scottImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">SCOTT RIGGS</h6>
                  <p className="no-margin text-uppercase">
                    Chief Operating Officer
                  </p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/ericgraham">
                <div className="team-card-img-container">
                  <img src={ericGImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">ERIC GRAHAM</h6>
                  <p className="no-margin text-uppercase">General Counsel</p>
                </div>
              </Link>
            </div>
            {/* card end */}
          </div>
          {/* row end */}
          {/* row start */}
          <div className="team-row">
            {/* card start */}
            <div className="team-card">
              <Link to="/ryanwhite">
                <div className="team-card-img-container">
                  <img src={ryanImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">RYAN WHITE</h6>
                  <p className="no-margin text-uppercase">
                    Vice President of Business Development
                  </p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/carlpacheco">
                <div className="team-card-img-container">
                  <img src={CarlPImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">CARL PACHECO</h6>
                  <p className="no-margin text-uppercase">Operations Manager</p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/sealeduggar">
                <div className="team-card-img-container">
                  <img src={SealeDImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">SEALE DUGGAR</h6>
                  <p className="no-margin text-uppercase">Director of Contract Management</p>
                </div>
              </Link>
            </div>
            {/* card end */}
          </div>
          {/* row end */}
        </div>
      </div>
    </>
  );
};

export default Leadership;
