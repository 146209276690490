import React, { useState, useEffect } from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import LogoWhite from "../../assets/images/logo-white.png";
import Image from "react-bootstrap/Image";
import loginImg from "../../assets/images/loginicon.png";

import "../navBar/NavigationBar.css";

const NavigationBar = () => {
  const [transform, setTransform] = useState(false);

  useEffect(() => {
    // initiate the event handler
    window.addEventListener("scroll", handleScroll, true);

    // this will clean up the event every time the component is re-rendered
    return function cleanup() {
      window.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 10) {
      setTransform(true);
    } else {
      setTransform(false);
    }
  };

  const handleHomePress = () => {
    window.scrollTo({ top: (0, 0), behavior: "smooth" });
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -90;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        fixed="top"
        variant="dark"
        className={transform === true ? "nav_color" : "nav_clear"}
      >
        <Navbar.Brand href="#home" onClick={() => handleHomePress()}>
          <Image src={LogoWhite} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav>
            <NavLink
              to="/#about-us"
              activeClassName="selected"
              className="nav_link_white"
              scroll={(el) => scrollWithOffset(el)}
            >
              About Us
            </NavLink>
            <NavLink
              smooth
              to="/#why-work-with-us"
              activeClassName="selected"
              className="nav_link_white"
              scroll={(el) => scrollWithOffset(el)}
            >
              Why Work with us
            </NavLink>
            <a
              href="http://stratcaptowers.com/"
              activeClassName="selected"
              className="nav_link_white"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tower Locations
            </a>
            <NavLink
              smooth
              to="/#seeking-investments"
              activeClassName="selected"
              className="nav_link_white"
              scroll={(el) => scrollWithOffset(el)}
            >
              Seeking Investments
            </NavLink>
            <NavLink
              smooth
              to="/#leadership"
              activeClassName="selected"
              className="nav_link_white"
              scroll={(el) => scrollWithOffset(el)}
            >
              Leadership
            </NavLink>
            {/* <NavLink
              smooth
              to="/#financial-accounting"
              activeClassName="selected"
              className="nav_link_white"
              scroll={(el) => scrollWithOffset(el)}
            >
              Financial & Accounting Services
            </NavLink> */}
            <NavLink
              smooth
              to="/#contact-us"
              activeClassName="selected"
              className="nav_link_white"
              scroll={(el) => scrollWithOffset(el)}
            >
              Contact us
            </NavLink>
            {/* <a
              href="https://bfwebuat.bfwebsolutions.com/BFWeb/clients/strategicfunds/index"
              activeClassName="selected"
              className="login-flex nav_link_white"
            >
              <img src={loginImg} className="login-img" alt="login" />
              <span className="ip-login-text">Investor Portal Login</span>
            </a> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavigationBar;
