import React from "react";
import { Link } from "react-router-dom";

import "../leadership/Leadership.css";

import scottCorkeryImg from "../../assets/images/ScottCorkery.jpg";
import scottJacobsImg from "../../assets/images/ScottJacobs.jpg";
import brandonImg from "../../assets/images/BrandonHunt.jpg";
import brianEImg from "../../assets/images/StratCap-BrianEng.jpg";
import robertMImg from "../../assets/images/StratCap-RobertMoreta.jpg";
import karenImg from "../../assets/images/KarenSiu_500x500.jpg";
import christineImg from "../../assets/images/ChristineNguyen.jpg";
import mattImg from "../../assets/images/MattSimmavong.jpg";

const FinancialAccounting = (props) => {
  return (
    <>
      <div
        className="faservices_main_container"
        ref={props.refProp}
        id="financial-accounting"
      >
        <h1 className="heading-blue green-heading">
          FINANCE & ASSET MANAGEMENT
        </h1>
        <div className="leadership_content">
          {/* row start */}
          <div className="team-row">
            {/* card start */}
            <div className="team-card">
              <Link to="/scottcorkery">
                <div className="team-card-img-container">
                  <img
                    src={scottCorkeryImg}
                    alt="team"
                    className="team-card-img"
                  />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">
                    SCOTT W. CORKERY, CPA, CA
                  </h6>
                  <p className="no-margin">CHIEF FINANCIAL OFFICER</p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/scottjacobs">
                <div className="team-card-img-container">
                  <img
                    src={scottJacobsImg}
                    alt="team"
                    className="team-card-img"
                  />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">
                    SCOTT JACOBS, MBA
                  </h6>
                  <p className="no-margin">
                    VP OF ACQUISITIONS AND ASSET MANAGEMENT
                  </p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/brandonhunt">
                <div className="team-card-img-container">
                  <img src={brandonImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">BRANDON HUNT</h6>
                  <p className="no-margin">PORTFOLIO MANAGER</p>
                </div>
              </Link>
            </div>
            {/* card end */}
          </div>
          {/* row end */}
          {/* row start */}
          <div className="team-row">
            {/* card start */}
            <div className="team-card">
              <Link to="/karensiu">
                <div className="team-card-img-container">
                  <img src={karenImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">KAREN SIU</h6>
                  <p className="no-margin">CHIEF ACCOUNTING OFFICER</p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/brianeng">
                <div className="team-card-img-container">
                  <img src={brianEImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">BRIAN ENG</h6>
                  <p className="no-margin">CONTROLLER</p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/robertmoretta">
                <div className="team-card-img-container">
                  <img src={robertMImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">
                    ROBERT MORETTA
                  </h6>
                  <p className="no-margin">ASSISTANT CONTROLLER</p>
                </div>
              </Link>
            </div>
            {/* card end */}
          </div>
          {/* row end */}
          {/* row start */}
          <div className="team-row">
            {/* card start */}
            <div className="team-card">
              <Link to="/christinenguyen">
                <div className="team-card-img-container">
                  <img
                    src={christineImg}
                    alt="team"
                    className="team-card-img"
                  />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">
                    CHRISTINE NGUYEN
                  </h6>
                  <p className="no-margin">SENIOR ACCOUNTANT</p>
                </div>
              </Link>
            </div>
            {/* card end */}
            {/* card start */}
            <div className="team-card">
              <Link to="/mattsimmavong">
                <div className="team-card-img-container">
                  <img src={mattImg} alt="team" className="team-card-img" />
                </div>
                <div className="team-card-body">
                  <h6 className="team-title no-margin-bottom">
                    MATT SIMMAVONG
                  </h6>
                  <p className="no-margin">SENIOR ACCOUNTANT</p>
                </div>
              </Link>
            </div>
            {/* card end */}
          </div>
          {/* row end */}
        </div>
      </div>
    </>
  );
};

export default FinancialAccounting;
