import React, { useEffect } from "react";

import NavbarRoute from "./NavbarRoute";

import MainImg from "../assets/images/KarenSiu_500x500.jpg";

import "./Routes.css";

const KarenRoute = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bryan-route-container circuit-board-wp">
        <NavbarRoute />
        <span></span>
        <div className="route-content-container">
          <div className="bryan-route-content-left">
            <img src={MainImg} alt="karen siu" className="route-img" />
            <h1 className="bryan-route-headerone no-margin">KAREN SIU</h1>
            <h2 className="bryan-route-headertwo no-margin">
              CHIEF ACCOUNTING OFFICER
            </h2>
          </div>
          <div className="bryan-route-content-right">
            <p className="no-margin-top">
              Karen Siu currently serves as the Chief Accounting Officer for
              Strategic Wireless. She has extensive experience in hedge funds,
              private equity, and the real estate accounting space. Before
              joining the company, she was the Controller of real estate
              accounting at Sculptor Capital, and before that role, she served
              as Manager at Goldman Sachs Asset Management. She also previously
              worked as a Senior Accountant at York Capital Management and
              PricewaterhouseCoopers.
            </p>
            <p>
              Karen holds a Bachelor of Science in Accounting and Information
              Studies from Syracuse University and is a Certified Public
              Accountant.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default KarenRoute;
