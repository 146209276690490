import React, { useEffect } from "react";

import NavbarRoute from "./NavbarRoute";

import MainImg from "../assets/images/StratCap-BrianEng.jpg";

import "./Routes.css";

const BrianERoute = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bryan-route-container circuit-board-wp">
        <NavbarRoute />
        <span></span>
        <div className="route-content-container">
          <div className="bryan-route-content-left">
            <img src={MainImg} alt="brian eng" className="route-img" />
            <h1 className="bryan-route-headerone no-margin">BRIAN ENG</h1>
            <h2 className="bryan-route-headertwo no-margin">CONTROLLER</h2>
          </div>
          <div className="bryan-route-content-right">
            <p className="no-margin-top">
              Brian Eng currently serves as a Controller for Strategic Wireless.
              He has extensive experience in the private equity and real estate
              fund accounting space. Before joining the company, Brian was a
              Fund Accounting Manager at Sculptor Capital. He also previously
              worked as a Senior Assurance Associate at RSM US.
            </p>
            <p>
              Brian holds a Bachelor of Science and Master of Science in
              Accounting from Binghamton University.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BrianERoute;
