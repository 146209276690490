import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "./WhyWorkWithUs.css";

const WhyWorkWithUs = (props) => {
  return (
    <>
      <div ref={props.refProp} className="blue-bg why-work-container" id="why-work-with-us">
        <Container fluid>
          <h2 className="heading-white green-heading">why work with us</h2>
          <Row className="justify-content-md-center">
            <Col sm={12} md={12} lg={5} xl={5} className="content-padding">
              <h2 className="white-text h-2 sub-heading">EXPERIENCE</h2>
              <h4 className="white-text h-4">
                We are a firm comprised of experienced corporate executives that
                understand the real world challenges faced by management in
                growing enterprises.
              </h4>
            </Col>
            <Col sm={12} md={12} lg={5} xl={5} className="content-padding">
              <h2 className="white-text h-2 sub-heading">PARTNER</h2>
              <h4 className="white-text h-4">
                We have a true partnering perspective with the management teams
                we support and we strive to properly align our interests with
                our partners and create lasting relationships.
              </h4>
            </Col>
            <Col sm={12} md={12} lg={5} xl={5} className="content-padding">
              <h2 className="white-text h-2 sub-heading">SUPPORT</h2>
              <h4 className="white-text h-4">
                We have a network of experienced financial, strategic,
                operational and marketing resources designed to support the
                business needs of our partner clients.
              </h4>
            </Col>
            <Col sm={12} md={12} lg={5} xl={5} className="content-padding">
              <h2 className="white-text h-2 sub-heading">TRANSPARENCY</h2>
              <h4 className="white-text h-4">
                We are transparent during our evaluation period and want you to
                understand us, our philosophy and objectives as much as we want
                to know you and your business.
              </h4>
            </Col>
            <Col sm={12} md={12} lg={5} xl={5} className="content-padding">
              <h2 className="white-text h-2 sub-heading">EXCLUSIVITY</h2>
              <h4 className="white-text h-4">
                Relative to the big tower companies, we are a small firm that
                seeks to partner with select companies and developers, devoting
                ourselves to success with those enterprises.
              </h4>
            </Col>
            <Col sm={12} md={12} lg={5} xl={5} className="content-padding">
              <h2 className="white-text h-2 sub-heading">TRUST</h2>
              <h4 className="white-text h-4">
                Our team has a track record of successful business ventures and
                transactions completed within a highly ethical and responsible
                framework with references to support our claims.
              </h4>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default WhyWorkWithUs;
