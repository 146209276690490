import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import "./SeekingInvestments.css";

import easementsImg from "../../assets/images/wirelesseasements.jpg";
import cellTowersImg from "../../assets/images/celltower.jpg";
import fiberOpticImg from "../../assets/images/fiber.jpg";
import smallCellsImg from "../../assets/images/smallcells.jpg";
import dasImg from "../../assets/images/das.jpg";

const SeekingInvestments = (props) => {
  return (
    <>
      <div ref={props.refProp} className="investments-container green-bg" id="seeking-investments">
        <Container fluid>
          <h2 className="heading-white blue-heading">
            ACTIVELY SEEKING INVESTMENTS & ACQUISITIONS
          </h2>
          <Row>
            <div className="seeking-info-div">
              <h5 className="h-5 white-text content-padding ">
                We are actively seeking investment, acquisition and development
                opportunities in a variety of assets. If you are in need of a
                build-to-suite capital partner or looking for full/partial
                liquidation opportunities, we can ensure a smooth process, rapid
                timing and fair transaction value.
              </h5>
            </div>
            <Container fluid className="container-padding">
              <Row className="justify-content-md-center">
                <Col xl={2} lg={5} md={5} sm={12} className="text-center">
                  <div>
                    <Image src={cellTowersImg} fluid className="seeking-img" />
                    <p className="text-center text-uppercase white-text seeking-img-titles">
                      Cell Towers
                    </p>
                  </div>
                </Col>
                <Col xl={2} lg={5} md={5} sm={12} className="text-center">
                  <div>
                    <Image src={easementsImg} fluid className="seeking-img" />
                    <p className="text-center text-uppercase white-text seeking-img-titles">
                      WIRELESS EASEMENTS
                    </p>
                  </div>
                </Col>
                <Col xl={2} lg={5} md={5} sm={12} className="text-center">
                  <div>
                    <Image src={fiberOpticImg} fluid className="seeking-img" />
                    <p className="text-center text-uppercase white-text seeking-img-titles">
                      FIBER OPTIC NETWORKS
                    </p>
                  </div>
                </Col>
                <Col xl={2} lg={5} md={5} sm={12} className="text-center">
                  <div>
                    <Image src={smallCellsImg} fluid className="seeking-img" />
                    <p className="text-center text-uppercase white-text seeking-img-titles">
                      SMALL CELLS
                    </p>
                  </div>
                </Col>
                <Col xl={2} lg={5} md={5} sm={12} className="text-center">
                  <div>
                    <Image src={dasImg} fluid className="seeking-img" />
                    <p className="text-center text-uppercase white-text seeking-img-titles">
                      DAS
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default SeekingInvestments;
