import React from "react";

import heroVideo from "../../assets/videos/homepage_video.mp4";

import "./Hero.css";

const Hero = (props) => {
  return (
    <>
      <div className="hero-container" ref={props.refProp}>
        <video autoPlay muted loop id="hero_video">
          <source src={heroVideo} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
        <div className="hero_content_wrapper">
          <p>
            We are a digital infrastructure company focused on acquiring,
            building and managing telecom infrastructure assets that support
            mobile and internet connectivity
          </p>
        </div>
      </div>
      <div className="hero-content-show-small">
        <p>
          We are a digital infrastructure company focused on acquiring, building
          and managing telecom infrastructure assets that support mobile and
          internet connectivity
        </p>
      </div>
    </>
  );
};

export default Hero;
