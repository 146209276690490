import React from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import LogoWhite from "../assets/images/logo-white.png";
import Image from "react-bootstrap/Image";
import loginImg from "../assets/images/loginicon.png";

import "../components/navBar/NavigationBar.css";
import "./Routes.css";

const NavbarRoute = () => {
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = 30;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        fixed="top"
        variant="dark"
        className="nav_color"
      >
        <Navbar.Brand href="/">
          <Image src={LogoWhite} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto"></Nav>
          <Nav>
            <NavLink
              to="/#about-us"
              activeClassName="selected"
              className="nav_link_white"
              scroll={(el) => scrollWithOffset(el)}
            >
              About Us
            </NavLink>
            <NavLink
              smooth
              to="/#why-work-with-us"
              activeClassName="selected"
              className="nav_link_white"
              scroll={(el) => scrollWithOffset(el)}
            >
              Why Work with us
            </NavLink>
            <NavLink
              smooth
              to="/#seeking-investments"
              activeClassName="selected"
              className="nav_link_white"
              scroll={(el) => scrollWithOffset(el)}
            >
              Seeking Investments
            </NavLink>
            <NavLink
              smooth
              to="/#leadership"
              activeClassName="selected"
              className="nav_link_white"
              scroll={(el) => scrollWithOffset(el)}
            >
              Leadership
            </NavLink>
            <NavLink
              smooth
              to="/#financial-accounting"
              activeClassName="selected"
              className="nav_link_white"
              scroll={(el) => scrollWithOffset(el)}
            >
              Financial & Accounting Services
            </NavLink>
            <NavLink
              smooth
              to="/#contact-us"
              activeClassName="selected"
              className="nav_link_white"
              scroll={(el) => scrollWithOffset(el)}
            >
              Contact us
            </NavLink>
            {/* <a
              href="https://bfwebuat.bfwebsolutions.com/BFWeb/clients/strategicfunds/index"
              activeClassName="selected"
              className="login-flex nav_link_white"
            >
              <img src={loginImg} className="login-img" alt="login" />
              <span className="ip-login-text">Investor Portal Login</span>
            </a> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavbarRoute;
