import React, { useEffect } from "react";

import NavbarRoute from "./NavbarRoute";

import MainImg from "../assets/images/todd_large.jpg";

import "./Routes.css";

const ToddPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bryan-route-container circuit-board-wp">
        <NavbarRoute />
        <span></span>
        <div className="route-content-container">
          <div className="bryan-route-content-left">
            <img src={MainImg} alt="bryan" className="route-img" />
            <h1 className="bryan-route-headerone no-margin">TODD ROWLEY</h1>
            <h2 className="bryan-route-headertwo no-margin text-uppercase">
              Chief Executive Officer
            </h2>
          </div>
          <div className="bryan-route-content-right">
            <p className="no-margin-top">
              Todd A. Rowley is a seasoned telecommunications executive with
              over three decades of telecom industry experience, including
              wireless, wireline, cable television, and other related
              industries. Mr. Rowley serves as the Chief Executive Officer and
              is responsible for identifying and structuring wireless
              infrastructure investment opportunities. Mr. Rowley has an
              extensive background in corporate and business development and
              strategy and has also held several significant operating roles.
            </p>
            <p>
              Over his career, Mr. Rowley has led or participated in dozens of
              transactions accounting for over $125 billion in value. He has
              extensive experience with strategic partnering, M&A transactions,
              strategic analysis, financings, joint ventures, evaluating and
              developing new business opportunities, and complex negotiations.
              Mr. Rowley spent 17 years in various executive roles at Sprint.
              During his tenure, he managed Sprint’s extensive spectrum
              portfolio, led dozens of spectrum transactions, and established
              the company’s overall spectrum and roaming strategies.
            </p>
            <p>
              He was also a key member of the team that evaluated and made
              critical technology decisions related to 3G, 4G, LTE, and WiMAX,
              and then led efforts to contract with vendors such as Samsung,
              Nokia, Motorola, Ericsson, Qualcomm, and Intel. He led and managed
              the deployment of 4G/WiMAX for Sprint, one of the country’s first
              4G products, and, together with his team, managed all aspects of
              the 4G roll-out, including product, pricing, marketing, sales,
              distribution, network, and care. He grew the product to over six
              million customers in three years and helped spearhead the first 4G
              products, including one of the first dual-mode 3G/4G handsets. Mr.
              Rowley holds a Bachelors in Business Administration with majors in
              finance and marketing from the University of Iowa.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ToddPage;
