import React from "react";

import "./ContactUs.css";

import phoneImg from "../../assets/images/PHONE.png";
import emailImg from "../../assets/images/MAIL.png";
import pinImg from "../../assets/images/pin.png";

const ContactUs = (props) => {
  return (
    <>
      <div ref={props.refProp} className="contact-container" id="contact-us">
        <h1 className="heading-white green-heading">Contact Us</h1>
        <div className="contact-content-container">
          <div className="contact-img-container">
            <div className="contact-img-left">
              <img src={phoneImg} alt="phone" className="contact-images" />
              <p className="contact-large-print">
                <a href="tel:4752820586" className="phone-number">
                  615-814-2786
                </a>
              </p>
            </div>
            <div className="contact-img-left">
              <img src={pinImg} alt="phone" className="contact-images" />
              <p className="contact-large-print">
                <a
                  href="https://goo.gl/maps/hunustYSzPfoix567"
                  className="phone-number"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  1000 Corporate Centre Drive Suite #130 Franklin, TN 37067
                </a>
              </p>
            </div>
            <div className="contact-img-right">
              <img src={emailImg} alt="email" className="contact-images" />
              <p className="contact-large-print">
                <a
                  href="mailto:info@stratcapwireless.com"
                  className="phone-number"
                >
                  info@stratcapwireless.com
                </a>
              </p>
            </div>
          </div>
          <div className="contact-copyright">
            <p className="contact-bottom">
              © 2019 Copyright All Rights Reserved | StratCap Wireless, LLC
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
