import React, { useEffect } from "react";

import NavbarRoute from "./NavbarRoute";

import MainImg from "../assets/images/ChristineNguyen.jpg";

import "./Routes.css";

const ChristineRoute = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bryan-route-container circuit-board-wp">
        <NavbarRoute />
        <span></span>
        <div className="route-content-container">
          <div className="bryan-route-content-left">
            <img src={MainImg} alt="bryan" className="route-img" />
            <h1 className="bryan-route-headerone no-margin">
              CHRISTINE NGUYEN
            </h1>
            <h2 className="bryan-route-headertwo no-margin">
              SENIOR ACCOUNTANT
            </h2>
          </div>
          <div className="bryan-route-content-right">
            <p className="no-margin-top">
              Christine currently serves as Senior Accountant for the company
              and its affiliates. She has over eight years of accounting
              experience in commercial real estate, alternative investments,
              asset management, REITs, non-profits, and government
              organizations. She has been involved in $18 billion in investment
              offerings throughout her career. She plays an integral role
              preparing financial reports for the company.
            </p>
            <p>
              Christine holds a Bachelor of Science from the Mihaylo College of
              Business and Economics at California State University Fullerton
              (CSUF) with a concentration in Accounting. Her studies included
              taxes, financial accounting, and corporate finance.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChristineRoute;
