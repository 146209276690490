import React, { useState } from "react";

import PrivacyPolicy from "./PP";
import TermsOfUse from "./TOU";

import "./Footer.css";

const Footer = () => {
  const [privacy, setPrivacy] = useState(false);
  const [tou, setTou] = useState(false);
  return (
    <div>
      <div className="footer-container">
        <div className="footer-content-wrapper">
          <div className="footer-privacy-wrapper">
            <p
              className="footer-content-top-label"
              onClick={() => setPrivacy(true)}
            >
              Privacy Policy
            </p>
            <p className="footer-content-bottom-label">
              We have updated our privacy policy. Click above to review.
            </p>
          </div>
          <div className="footer-tou-wrapper">
            <p
              className="footer-content-top-label"
              onClick={() => setTou(true)}
            >
              Terms Of Use
            </p>
            <p className="footer-content-bottom-label">
              We have updated our terms of use. Click above to review.
            </p>
          </div>
        </div>
      </div>
      {/* <!-- The Modal --> */}
      <div
        id="myModal"
        className={privacy === false ? "modalhide" : "modalshow"}
      >
        {/* <!-- Modal content --> */}
        <div className="footer-modal-content">
          <p className="modal-close-button">
            <span className="close-button" onClick={() => setPrivacy(false)}>
              {" "}
              &times;
            </span>
          </p>
          <PrivacyPolicy />
        </div>
      </div>
      {/* <!-- The Modal --> */}
      <div id="myModal" className={tou === false ? "modalhide" : "modalshow"}>
        {/* <!-- Modal content --> */}
        <div className="footer-modal-content">
          <p className="modal-close-button">
            <span className="close-button" onClick={() => setTou(false)}>
              {" "}
              &times;
            </span>
          </p>
          <TermsOfUse />
        </div>
      </div>
    </div>
  );
};

export default Footer;
