import React, { useEffect } from "react";

import NavbarRoute from "./NavbarRoute";

import MainImg from "../assets/images/StratCap-SealeDuggar.jpg";

import "./Routes.css";

const SealeDPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="bryan-route-container circuit-board-wp">
        <NavbarRoute />
        <span></span>
        <div className="route-content-container">
          <div className="bryan-route-content-left">
            <img src={MainImg} alt="seale duggar" className="route-img" />
            <h1 className="bryan-route-headerone no-margin">SEALE DUGGAR</h1>
            <h2 className="bryan-route-headertwo no-margin text-uppercase">
              Director of Contract Management
            </h2>
          </div>
          <div className="bryan-route-content-right">
            <p className="no-margin-top">
              Seale Duggar is based out of Nashville, TN where she was born and
              raised. She attended Samford University and earned a Bachelor of
              Science in Business Administration. Shortly after graduating, she
              moved to the Jackson, MS area for several years before returning
              to Nashville. Seale has over a decade of experience in the
              telecommunications and technology industry, with a focus on
              process improvement and management. She is an avid sports fan and
              enjoys attending sporting events with her friends and family.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SealeDPage;
