import React from "react";

// import Navigation from "../navBar/NavigationBar";
import Navigation from "../TEST/NavReference";

import Hero from "../hero/Hero";
import AboutUs from "../aboutUs/AboutUs";
import WhyWorkWithUs from "../whyWorkWithUs/WhyWorkWithUs";
import SeekingInvestments from "../seekingInvestments/SeekingInvestments";
import Leadership from "../leadership/Leadership";
import FinancialAccounting from "../financialAccounting/FinancialAccounting";
import ContactUs from "../contactUs/ContactUs";
import Footer from "../footer/Footer";

const Home = () => {
  return (
    <>
      <Navigation />
      <Hero />
      <AboutUs />
      <WhyWorkWithUs />
      <SeekingInvestments />
      <Leadership />
      <FinancialAccounting />
      <ContactUs />
      <Footer />
    </>
  );
};

export default Home;
